import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  ak: 'U39oaT0F3Q9fFxOaL2hUHiLpPznEaXGY'
})

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import zhLocale from 'element-ui/lib/locale/lang/en'
import '@/styles/index.scss'

import router from './router'

import 'lib-flexible/flexible'

// Vue.use(ElementUI, { locale })
Vue.use(ElementUI, { zhLocale })
Vue.use(VueRouter)
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})

